import React, {useState} from "react";
import logo from "../assets/logo.png";
import {useNavigate} from "react-router-dom";
import {ButtonText, NavBarText, PopUpText} from "../Utils/Helper/enums";

interface NavbarProps {
  isOpen: boolean;
  onToggle: () => void;
}

const Navbar: React.FC<NavbarProps> = ({isOpen, onToggle}) => {
  const [isLogoutPopupOpen, setLogoutPopupOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    setLogoutPopupOpen(false);
    navigate("/");
  };

  return (
    <nav className="bg-slate-300 py-4 px-6 relative">
      <div className="container mx-auto flex justify-between items-center">
        <img src={logo} width="100" height="100" alt="Logo" />
        <div className="flex items-center">
          <button
            className="text-black focus:outline-none focus:shadow-outline"
            onClick={onToggle}>
            <svg
              className="h-6 w-6 transition-transform duration-300 ease-in-out transform"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{
                transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)",
              }}>
              <path d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        <div
          className={`absolute right-4 top-full w-full lg:w-48 bg-white rounded-md shadow-md transition-opacity duration-300 ${
            isOpen
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}>
          <ul>
            {[
              {text: NavBarText.TestCaseSpecs, link: "#"},
              {text: NavBarText.TestCaseToUserProjectLinks, link: "#"},
              {text: NavBarText.ResultsByUserProjectsLinks, link: "#"},
              {text: NavBarText.ResultsByTestCaseSpecs, link: "#"},
              {text: NavBarText.LogOut, link: "#"},
            ].map((item, index) => (
              <li key={index}>
                {item.text === "Log Out" ? (
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => setLogoutPopupOpen(true)}>
                    {item.text}
                  </a>
                ) : (
                  <a
                    href={item.link}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    {item.text}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
        {isLogoutPopupOpen && (
          <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 flex justify-center items-center">
            <div className="bg-white p-8 rounded-md shadow-md flex flex-col items-center">
              <p>{PopUpText.LogOutPopUp}</p>
              <div className="flex justify-center mt-4">
                <button
                  className="mr-4 px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
                  onClick={() => {
                    setLogoutPopupOpen(false);
                    !isOpen;
                  }}>
                  {ButtonText.Cancel}
                </button>
                <button
                  className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                  onClick={handleLogout}>
                  {ButtonText.Ok}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
