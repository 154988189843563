interface TestResult {
  key: string;
  jiraProductKey: string;
  description: string;
  hubType: string;
  pass: number;
  fail: number;
  skip: number;
  null: number;
}

export const dashboard: TestResult[] = [
  {
    jiraProductKey: "TCS-55",
    description: "TCS-55",
    hubType: "No Hub / Terminology",
    pass: 12,
    fail: 10,
    skip: 4,
    null: 0,
    key: "Terminology",
  },
  {
    jiraProductKey: "TCS-54",
    description: "TCS-54",
    hubType: "No Hub / General",
    pass: 34,
    fail: 0,
    skip: 0,
    null: 0,
    key: "General",
  },
  {
    jiraProductKey: "TCS-53",
    description: "TCS-53",
    hubType: "No Hub / Hardware",
    pass: 23,
    fail: 0,
    skip: 0,
    null: 0,
    key: "Hardware",
  },
  {
    jiraProductKey: "TCS-52",
    description: "TCS-52",
    hubType: "No Hub / System Software",
    pass: 56,
    fail: 0,
    skip: 0,
    null: 0,
    key: "System Software",
  },
  {
    jiraProductKey: "TCS-48",
    description: "TCS-48",
    hubType: "No Hub / Wi-Fi",
    pass: 98,
    fail: 0,
    skip: 0,
    null: 0,
    key: "Wi-Fi",
  },
  {
    jiraProductKey: "TCS-47",
    description: "TCS-47",
    hubType: "No Hub / WAN Interface",
    pass: 34,
    fail: 0,
    skip: 0,
    null: 0,
    key: "WAN Interface",
  },
  {
    jiraProductKey: "TCS-46",
    description: "TCS-46",
    hubType: "No Hub / LAN Interface",
    pass: 16,
    fail: 0,
    skip: 0,
    null: 0,
    key: "LAN Interface",
  },
  {
    jiraProductKey: "TCS-4572",
    description: "TCS-4572",
    hubType: "No Hub / USB Interface",
    pass: 98,
    fail: 0,
    skip: 0,
    null: 0,
    key: "USB Interface",
  },
  {
    jiraProductKey: "TCS-4571",
    description: "TCS-4571",
    hubType: "No Hub / IPv6",
    pass: 76,
    fail: 0,
    skip: 0,
    null: 0,
    key: "IPv6",
  },
  {
    jiraProductKey: "TCS-4570",
    description: "TCS-4570",
    hubType: "No Hub / Firewall",
    pass: 67,
    fail: 0,
    skip: 0,
    null: 0,
    key: "Firewall",
  },
];
