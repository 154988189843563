import React, {useState} from "react";
import TreeMenu from "./TreeMenu";
import Modal from "./Modal";
import Navbar from "./Navbar";
import {dashboard} from "../Data/DashboardData";
import {treeData} from "../Data/TreeData";

const TCTDashboard = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    "Test Case Specifications",
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilteredData, setShowFilteredData] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = (item: any) => {
    setSelectedMenuItem(item);
    setCurrentPage(1);
    setShowFilteredData(true);
    setSelectedFile(item);
  };

  const getPathToSelectedItem = (treeData: any[], selectedItem: string) => {
    const path: any[] = [];

    const traverse = (nodes: any[]) => {
      for (const node of nodes) {
        if (node.name === selectedItem) {
          path.push(node.name);
          return true;
        }
        if (node.children) {
          if (traverse(node.children)) {
            path.push(node.name);
            return true;
          }
        }
      }
      return false;
    };

    traverse(treeData);
    return path.reverse().join(" > ");
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setItemsPerPage(parseInt(event.target.value));
  };

  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchTerm(event.target.value);
  };

  const handleLinkClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Get the test results for the selected menu item
  const resultsForSelectedMenuItem = dashboard.filter(
    result => result.key === selectedMenuItem,
  );

  const allTestResults = dashboard.filter(
    result =>
      result.jiraProductKey.toLowerCase().includes(searchTerm.toLowerCase()) ||
      result.description.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  // Filter the test results based on search term
  const filteredTestResults = resultsForSelectedMenuItem.filter(
    result =>
      result.jiraProductKey.toLowerCase().includes(searchTerm.toLowerCase()) ||
      result.description.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  // Paginate the filtered test results
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = allTestResults.slice(indexOfFirstItem, indexOfLastItem);
  const currentItemsFiltered = filteredTestResults.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  const handleButtonClick = () => {
    alert("Function not implemented.");
  };

  return (
    <div>
      <Navbar isOpen={isOpen} onToggle={handleToggle} />
      <div className="bg-gray-100 min-h-screen flex">
        <div className="bg-slate-200 w-64 p-4">
          <TreeMenu nodes={treeData} onMenuItemClick={handleMenuItemClick} />
        </div>
        <main className="flex-1 p-4 overflow-y-auto">
          <div className="w-3/4">
            <h6 className="text-md font-bold mb-4 text-slate-300">
              {selectedFile
                ? getPathToSelectedItem(treeData, selectedFile)
                : `${treeData[0].name}`}
            </h6>
          </div>
          <header className="flex justify-between items-center mb-4">
            <div className="text-xl font-bold">TCT R4J Dashboard</div>
            <div>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={handleButtonClick}>
                Generate Report
              </button>
            </div>
          </header>
          <section className="bg-white p-4 rounded shadow">
            <h2 className="text-lg font-semibold mb-4">
              Requirement: {selectedMenuItem}
            </h2>
            <div className="mb-4">
              <p>Version: r2.48.1-R-1077393</p>
              <p>Hub Type: -</p>
            </div>
            <>
              {showFilteredData && resultsForSelectedMenuItem.length > 0 ? (
                <>
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded float-right -mt-[2rem]"
                    onClick={handleLinkClick}>
                    View Chart
                  </button>
                  &nbsp; &nbsp;
                  <div className="overflow-x-auto border">
                    <table className="min-w-full bg-white table-auto">
                      <thead>
                        <tr>
                          <th className="border px-4 py-2 text-left">
                            Jira Product Keys
                          </th>
                          <th className="border px-4 py-2 text-left">
                            Description
                          </th>
                          <th className="border px-4 py-2 text-left">
                            Hub Type
                          </th>
                          <th className="border px-4 py-2 text-left">Pass</th>
                          <th className="border px-4 py-2 text-left">Fail</th>
                          <th className="border px-4 py-2 text-left">Skip</th>
                          <th className="border px-4 py-2 text-left">Null</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItemsFiltered.map(result => (
                          <tr key={result.jiraProductKey}>
                            <td className="border px-4 py-2">
                              {result.jiraProductKey}
                            </td>
                            <td className="border px-4 py-2">
                              {result.description}
                            </td>
                            <td className="border px-4 py-2">
                              {result.hubType}
                            </td>
                            <td className="border px-4 py-2">{result.pass}</td>
                            <td className="border px-4 py-2">{result.fail}</td>
                            <td className="border px-4 py-2">{result.skip}</td>
                            <td className="border px-4 py-2">{result.null}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-4">
                    {Array.from(
                      {
                        length: Math.ceil(
                          filteredTestResults.length / itemsPerPage,
                        ),
                      },
                      (_, i) => i + 1,
                    ).map(page => (
                      <button
                        key={page}
                        className={`mx-1 px-3 py-1 rounded-md ${
                          page === currentPage
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handlePageChange(page)}>
                        {page}
                      </button>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded float-right -mt-[2rem]"
                    onClick={handleLinkClick}>
                    View Chart
                  </button>
                  &nbsp; &nbsp;
                  <div className="overflow-x-auto border">
                    <table className="min-w-full bg-white table-auto">
                      <thead>
                        <tr>
                          <th className="border px-4 py-2 text-left">
                            Jira Product Keys
                          </th>
                          <th className="border px-4 py-2 text-left">
                            Description
                          </th>
                          <th className="border px-4 py-2 text-left">
                            Hub Type
                          </th>
                          <th className="border px-4 py-2 text-left">Pass</th>
                          <th className="border px-4 py-2 text-left">Fail</th>
                          <th className="border px-4 py-2 text-left">Skip</th>
                          <th className="border px-4 py-2 text-left">Null</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map(result => (
                          <tr key={result.jiraProductKey}>
                            <td className="border px-4 py-2">
                              {result.jiraProductKey}
                            </td>
                            <td className="border px-4 py-2">
                              {result.description}
                            </td>
                            <td className="border px-4 py-2">
                              {result.hubType}
                            </td>
                            <td className="border px-4 py-2">{result.pass}</td>
                            <td className="border px-4 py-2">{result.fail}</td>
                            <td className="border px-4 py-2">{result.skip}</td>
                            <td className="border px-4 py-2">{result.null}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-4">
                    {Array.from(
                      {
                        length: Math.ceil(allTestResults.length / itemsPerPage),
                      },
                      (_, i) => i + 1,
                    ).map(page => (
                      <button
                        key={page}
                        className={`mx-1 px-3 py-1 rounded-md ${
                          page === currentPage
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handlePageChange(page)}>
                        {page}
                      </button>
                    ))}
                  </div>
                </>
              )}
            </>
          </section>
        </main>
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          dashboard={dashboard}
        />
      </div>
    </div>
  );
};

export default TCTDashboard;
