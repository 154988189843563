export const treeData = [
  {
    name: "Test Case Specifications",
    children: [
      {name: "Terminology"},
      {name: "General"},
      {name: "Hardware"},
      {
        name: "Software",
        children: [
          {name: "System Software"},
          {
            name: "Residential Gateway Core Functions",
            children: [
              {name: "Wi-Fi"},
              {
                name: "Routing",
                children: [
                  {name: "WAN Interface"},
                  {name: "LAN Interface"},
                  {name: "USB Interface"},
                  {name: "IPv6"},
                  {name: "Firewall"},
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
