import React, {useEffect} from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import TCTDashboard from "./Dashboard";

interface IProtectedRoute {
  isAuthenticated: boolean;
}

const ProtectedRoutes: React.FC<IProtectedRoute> = ({isAuthenticated}) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Redirect to login page if user is not authenticated
    if (!isAuthenticated && location.pathname === "/dashboard") {
      navigate("/");
    }
  }, [isAuthenticated, location.pathname, navigate]);

  if (isAuthenticated) {
    return (
      <Routes>
        <Route path="/dashboard" element={<TCTDashboard />} />
      </Routes>
    );
  }

  // Redirect to login if not authenticated
  return <Navigate to="/" />;
};

export default ProtectedRoutes;
