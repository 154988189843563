import React, {useState} from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import TCTDashboard from "./Component/Dashboard";
import Login from "./Component/Login";
import ProtectedRoutes from "./Component/ProtectedRoute";

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/"
            element={<Login setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route
            element={<ProtectedRoutes isAuthenticated={isAuthenticated} />}>
            <Route path="/dashboard" element={<TCTDashboard />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default App;
