export enum Credentails {
  email = "example@example.com",
  password = "TestOperations@12345",
  emailOne = "example2@example.com",
  passwordOne = "TestingDashboard@12345"
}

export enum NavBarText {
  TestCaseSpecs = "Test Case Specs",
  TestCaseToUserProjectLinks = "Test case to User Project Links",
  ResultsByUserProjectsLinks = "Results by User Project Links",
  ResultsByTestCaseSpecs = "Results by Test Case Specs",
  LogOut = "Log Out",
}

export enum PopUpText {
  LogOutPopUp = "Are you sure you want to log out?",
  PieChart = "Test Results Pie Chart",
}

export enum ButtonText {
  Cancel = "Cancel",
  Ok = "Ok",
}
